import React, { Component } from "react";
import Header from "../Headers/Header";
import Grid from "@material-ui/core/Grid";
import AccordonCommon from "../../CommonScreens/AccordonCommon";
import Loader from "../../loader";
import { createMuiTheme, withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { FormControl, InputLabel, Select, TextField, Typography } from "@material-ui/core";
import customStyle from "../../../Assets/CSS/stylesheet_UHS";
import MenuItem from "@material-ui/core/MenuItem";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import moment from "moment";
import axios from 'axios';
import configurations from "../../../configurations";
import Button from "@material-ui/core/Button";
import styles from "../../../Assets/CSS/stylesheet_UHS";
import Fab from "@material-ui/core/Fab";
import { Modal } from "react-bootstrap";
import Sample from "../../CommonScreens/sampleTextField";
import SampleName from '../../CommonScreens/sampleTextFieldName';
import CommonDropDwn from "../../CommonScreens/CommonDropDwn_1.js";
import { getMuiTheme } from '../../CommonScreens/AgentTable';
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import Footer from "../../CommonScreens/Footer";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from "@material-ui/core/Tooltip";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiPhoneNumber from "material-ui-phone-number";
import { CuramgetGateWayToken } from "../../authentication/utils.js";

var convert = require('xml-js');

var formData = new FormData();
formData.append("draw", "1");
formData.append("start", "0");
formData.append("length", "10");
formData.append("columns[0].data", "id");
formData.append("columns[0].name", "");
formData.append("columns[0].searchable", "true");
formData.append("columns[0].orderable", "true");
formData.append("columns[0].search.regex", "false");
formData.append("columns[0].search.value", "");

formData.append("columns[1].data", "createdDate");
formData.append("columns[1].name", "");
formData.append("columns[1].searchable", "true");
formData.append("columns[1].orderable", "true");
formData.append("columns[1].search.regex", "false");
formData.append("columns[1].search.value", "");

formData.append("columns[2].data", "email");
formData.append("columns[2].name", "");
formData.append("columns[2].searchable", "true");
formData.append("columns[2].orderable", "true");
formData.append("columns[2].search.regex", "false");
formData.append("columns[2].search.value", "");

formData.append("columns[3].data", "companyName");
formData.append("columns[3].name", "");
formData.append("columns[3].searchable", "true");
formData.append("columns[3].orderable", "true");
formData.append("columns[3].search.regex", "false");
formData.append("columns[3].search.value", "");


formData.append("order[0].column", "0");
formData.append("order[0].dir", "desc");
formData.append("search.regex", "false");
formData.append("search.value", "");

let requestOptions = {
    method: 'POST',
    body: formData,
};

const CustomButton = withStyles(
    customStyle.viewBtn
)(Button);
const CrudButton = withStyles(
    // styles.crudBtnAgent,
    styles.crudBtnAgentCuram,

)(Fab);

const NextButton = withStyles(
    customStyle.doneCuramBtn
)(Button);
const PurpleRadio = withStyles(customStyle.radioBtn)((props) => (
    <Radio color="default" {...props} />
));

const CssTextField = withStyles(theme => ({
    root: {
        '& .MuiInput-root': {
            "&:hover:not($disabled):not($focused):not($error):before": {
                borderBottom: '2px solid #533278'
            },

            '&.MuiInput-underline.Mui-focused:after': {
                borderBottom: '2px solid #533278',
            },

        }
    },
}))(TextField);


class Employer extends Component {

    constructor(props) {
        super(props);

        let USER_DATA = JSON.parse(sessionStorage.getItem('USER_DATA'));
        const today = new Date();
        const tomorrow = new Date(today);
        let currentDate = new Date(today);
        if (currentDate.getDate() === 1) {
            currentDate.setDate(1);
            currentDate.setMonth(today.getMonth());
        } else if (currentDate.getDate() > 1) {
            currentDate.setDate(1);
            currentDate.setMonth(today.getMonth() + 1);
        }
        this.setState({ minDate: currentDate, targetDate: currentDate })

        this.state = {
            employerList: [],
            count: 0,
            USER_DATA: USER_DATA,
            loaderShow: false,
            targetDate: null,
            rowIndex: 0,
            addEmployerModal: false,
            compName: '',
            zipCode: '',
            state: '',
            city: '',
            street: '',
            division: '',
            industry: '',
            codeTitle: '',
            code: '',
            firstName: '',
            lastName: '',
            workEmail: '',
            jobTitle: '',
            phone: '',
            divisionList: [],
            industryList: [],
            codeTitleList: [],
            codeList: [],
            countryCode: '',
            msgModal: false,
            errMsg: '',
            employerDetailsObj: [],
            disableSubmit: true,
            employerEdit: false,
            searchTypeValue: '',
            searchShow: false,
            searchText: '',
            rowsPerPage: 10,
            effectiveDate: new Date(currentDate),
            dateErr: false,
            birthDtFocus: false,
            birthDt: false,
            adminPhone: '',
            adminFirstName: '',
            adminLastName: '',
            adminEmail: '',
            adminCountryCode: '',
            empid: '',
            page: 0,
            copyEmpCheck: false,
            payrollCycle: 'Weekly',
            employerContribution: '',
            contributionType: 'Doller',
            accountTypes: [],
            bankName: '',
            accountName: '',
            routingNo: '',
            accountNumber: '',
            accountType: '',
            defaultCountryCode: '',
            showTrialModal: false,
            employerMode: 'trial',
            employerEmail: '',
            empId: '',
            isUpdated: false,
            isUpdate: false,
        }
    }

    componentDidMount() {
        axios.get('https://ipapi.co/json/').then((response) => {
            if (response && response.data) {
                let data = response.data;
                this.setState({
                    defaultCountryCode: data.country_calling_code
                });
            }
        })
        this.setState({
            loaderShow: true
        });
        this.getEmployerData();
        let obj = {
            "searchKey": "division",
            "searchValue": ""
        }
        axios.post(process.env.REACT_APP_BASE_URL + '/employer/getSIC', obj)
            .then(response => {
                let divisionList = [];
                for (let i = 0; i < response.data.response.length; i++) {
                    divisionList.push({
                        key: response.data.response[i].division,
                        value: response.data.response[i].division
                    });

                }
                this.setState({
                    divisionList: divisionList
                })
            })

        // fetch(configurations.enrollmentBaseURL + "/setuppayment/getAccountType")
        //     .then((response) => response.json())
        //     .then((response) => {
        //         this.setState({
        //             accountTypes: response.response,
        //         });
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
    }

    getEmployerData = () => {
        let URL = `${process.env.REACT_APP_BASE_URL}/employer/getEmployersByAgentId/${this.state.USER_DATA.agentId}?pageNumber=${this.state.page}&pageSize=${this.state.rowsPerPage}`
        axios.get(URL)
            
            .then(response => {
                if (response.data.response && response.data.response.data) {
                    this.setState({
                        employerList: response.data.response.data,
                        count: response.data.response.recordsFiltered,
                        loaderShow: false
                    })
                } else {
                    this.setState({ loaderShow: false })
                }
            }).catch((err) => {

            })
    }

    changePage = (page, rows) => {
        this.setState({
            loaderShow: true
        });
        if (page !== undefined && rows) {
            let nextPage = (page * rows)
            formData.set("start", nextPage.toString());
            formData.set("length", rows.toString());
        let URL = `${process.env.REACT_APP_BASE_URL}/employer/getEmployersByAgentId/${this.state.USER_DATA.agentId}?pageNumber=${page}&pageSize=${rows}`

        axios.get(URL)
                .then(data => {
                    if (data.data.response && data.data.response.data) {
                        this.setState({ employerList: data.data.response.data, rowsPerPage: rows, loaderShow: false, page: page })
                    } else {
                        this.setState({ loaderShow: false })
                    }
                });
        }
    }
    /* ========================== server side sorting logic================= */
    sort = (changedColumn, order) => {

        formData.set("start", "0");
        formData.set("order[0].column", changedColumn);
        formData.set("order[0].dir", order);
        let URL = `${process.env.REACT_APP_BASE_URL}/employer/getEmployersByAgentId/${this.state.USER_DATA.agentId}?pageNumber=${this.state.page}&pageSize=${this.state.rowsPerPage}`

        axios.get(URL)
           
            .then(data => {
                if (data.data.response && data.data.response.data) {
                    this.setState({ employerList: data.data.response.data, count: data.response.recordsFiltered, loaderShow: false })
                } else {
                    this.setState({ loaderShow: false })
                }
            });
    }

    addEmployerModal = () => {
        this.setState({
            loaderShow: true
        });
        axios.get('https://ipapi.co/json/').then((response) => {
            if (response && response.data) {
                let data = response.data;
                this.setState({
                    countryCode: "+1",
                    adminCountryCode: "+1"
                });
            }
        }).catch((error) => {
            console.log(error);
        });
        const today = new Date();
        const tomorrow = new Date(today);
        let currentDate = new Date(today);
        if (currentDate.getDate() === 1) {
            currentDate.setDate(1);
            currentDate.setMonth(today.getMonth());
        } else if (currentDate.getDate() > 1) {
            currentDate.setDate(1);
            currentDate.setMonth(today.getMonth() + 1);
        }
        this.setState({ minDate: currentDate, targetDate: currentDate })

        this.setState({

            addEmployerModal: true,
            loaderShow: false,
            errMsg: '',
            compName: '',
            zipCode: '',
            state: '',
            city: '',
            street: '',
            division: '',
            industry: '',
            codeTitle: '',
            code: '',
            firstName: '',
            lastName: '',
            workEmail: '',
            jobTitle: '',
            phone: '',
            industryList: [],
            codeTitleList: [],
            codeList: [],
            adminPhone: '',
            adminFirstName: '',
            adminLastName: '',
            adminEmail: '',
            empid: '',
            // effectiveDate: new Date(currentDate),
            bankName: '',
            accountName: '',
            routingNo: '',
            accountNumber: '',
            accountType: '',
            payrollCycle: 'Weekly',
            employerContribution: '',
            contributionType: 'Doller',
        }, () => this.checkValidFields());
        /*let obj = {
            "searchKey":"division",
            "searchValue": ""
        }
        axios.post(process.env.REACT_APP_BASE_URL + '/employer/getSIC', obj )
            .then(response => {
                let divisionList =[];
                for(let i=0; i<response.data.response.length; i++){
                    divisionList.push({key : response.data.response[i].division, value : response.data.response[i].division});
                }


            });*/
    }

    addEmployer = () => {
        this.setState({ loaderShow: true, copyEmpCheck: false, })
        let URL = process.env.REACT_APP_BASE_URL + '/employer/addEmployer';
        let newPhone = this.state.phone.replace(/[^a-zA-Z0-9]/g, '');


        let obj = {
            "id": this.state.employerEdit ? this.state.id : null,
            "companyName": this.state.compName.trim(),
            "firstName": this.state.firstName.trim(),
            "lastName": this.state.lastName.trim(),
            "jobTitle": this.state.jobTitle && this.state.jobTitle.trim(),
            "phone": this.state.phone ? this.state.defaultCountryCode + newPhone.substr(1) : '',
            "zip": this.state.zipCode,
            "state": this.state.state,
            "city": this.state.city,
            "street": this.state.street,
            // "effective_date": moment(this.state.effectiveDate).format('YYYY-MM-DD'),
            "brokerId": this.state.USER_DATA.agentId,
            "division": this.state.division,
            "industry": this.state.industry,
            "codeTitle": this.state.codeTitle,
            "code": this.state.code,
            email: this.state.workEmail,
            "payrollCycle": this.state.payrollCycle,
            "contributionType": this.state.contributionType,
            "employerContribution": parseFloat(this.state.employerContribution) || 0,
            "employerPayment":
            {
                "bankName": this.state.bankName && this.state.bankName.trim(),
                "accountName": this.state.accountName && this.state.accountName.trim(),
                "accountNumber": this.state.accountNumber,
                "accountType": this.state.accountType,
                "routingNo": this.state.routingNo,
            },
            adminEmail: this.state.adminEmail,
            email: this.state.workEmail,
            "payrollCycle": this.state.payrollCycle,
            "contributionType": this.state.contributionType,
            "employerContribution": parseFloat(this.state.employerContribution) || 0,
            "employerPayment":
            {
                "bankName": this.state.bankName && this.state.bankName.trim(),
                "accountName": this.state.accountName && this.state.accountName.trim(),
                "accountNumber": this.state.accountNumber,
                "accountType": this.state.accountType,
                "routingNo": this.state.routingNo,
            }
        }

        console.log('--- ', obj);
        if (this.state.employerEdit) {
            URL = process.env.REACT_APP_BASE_URL + '/employer/updateEmployer';
            obj.empid = this.state.empid
        }

        axios.get(process.env.REACT_APP_BASE_URL + `/employer/validateEmployerEmail/${this.state.workEmail}`)
            .then(res => {
                console.log('emailche', res.data.code);
                if (res.data.code == 202 && this.state.workEmail != this.state.oldEmail) {
                    this.setState({
                        loaderShow: false,
                        msgModal: true,
                        errMsg: "Employer already registered with this Email ID!",
                        refresh: true,
                        addEmployerModal: false,
                    });
                }
                else {
                    axios.post(URL, obj)
                        .then(response => {
                            let errMsg = '';
                            if (response.data.code === 200) {
                                errMsg = this.state.employerEdit ? `Employer <b>${this.state.firstName.trim()} ${this.state.lastName.trim()}</b> Updated Successfully !` : `Employer <b>${this.state.firstName.trim()} ${this.state.lastName.trim()}</b> Added Successfully !`
                            } else {
                                errMsg = response.data.message
                            }
                            const today = new Date();
                            const tomorrow = new Date(today);
                            let currentDate = new Date(today);
                            if (currentDate.getDate() === 1) {
                                currentDate.setDate(1);
                                currentDate.setMonth(today.getMonth());
                            } else if (currentDate.getDate() > 1) {
                                currentDate.setDate(1);
                                currentDate.setMonth(today.getMonth() + 1);
                            }
                            this.setState({ minDate: currentDate, targetDate: currentDate })

                            this.setState({
                                msgModal: true,
                                addEmployerModal: false,
                                errMsg: errMsg,
                                compName: '',
                                zipCode: '',
                                state: '',
                                city: '',
                                street: '',
                                division: '',
                                industry: '',
                                codeTitle: '',
                                code: '',
                                firstName: '',
                                lastName: '',
                                workEmail: '',
                                jobTitle: '',
                                phone: '',
                                industryList: [],
                                codeTitleList: [],
                                codeList: [],
                                countryCode: '',
                                employerEdit: false,
                                adminPhone: '',
                                adminFirstName: '',
                                adminLastName: '',
                                adminEmail: '',
                                adminCountryCode: '',
                                // effectiveDate: new Date(currentDate),
                                empid: '',
                                copyEmpCheck: false,
                                bankName: '',
                                accountName: '',
                                routingNo: '',
                                accountNumber: '',
                                accountType: '',
                                payrollCycle: 'Weekly',
                                employerContribution: '',
                                contributionType: 'Doller',
                                isUpdated: false
                            });
                            this.getEmployerData();
                        })
                }
            })
    }

    checkEnable = (add) => {
        const { compName, zipCode, state, city, street, division, industry, codeTitle, code, firstName, lastName, workEmail, jobTitle, phone } = this.state;
        console.log('city = = ', city);
        this.setState({ cityErr: false, stateErr: false })
        if (compName == '' || zipCode == '' || city == '' || state == '' || firstName == '' || lastName == '' || workEmail == '' || phone == '') {
            if (compName == '') this.setState({ compNameErr: true })
            if (zipCode == '') this.setState({ zipCodeErr: true })
            if (city == '') this.setState({ cityErr: true })
            if (state == '') this.setState({ stateErr: true })
            if (firstName == '') this.setState({ firstNameErr: true })
            if (lastName == '') this.setState({ lastNameErr: true })
            if (workEmail == '') this.setState({ workEmailErr: true })
            if (phone == '') this.setState({ phoneErr: true })
        } else {
            if (add) this.addEmployer()
        }
    }

    copyEmployerDetails = () => {
        console.log("==Clicked===");
        console.log(this.state.copyEmpCheck);
        if (this.state.copyEmpCheck == false) {
            this.setState({
                copyEmpCheck: true,
                adminFirstName: this.state.firstName,
                adminLastName: this.state.lastName,
                adminEmail: this.state.workEmail,
                adminPhone: this.state.phone,
                adminFirstNameErr: false,
                adminLastNameErr: false,
                adminEmailErr: false
            }, () => this.checkValidFields())

        } else {
            this.setState({
                copyEmpCheck: false,
                adminFirstName: '',
                adminLastName: '',
                adminEmail: '',
                adminPhone: '',
            }, () => this.checkValidFields())
        }
    }

    closeEmployerModal = () => {
        let today = new Date();
        let tomorrow = new Date(today);
        if (this.state.USER_DATA.clientId.toString() === '6548' || this.state.USER_DATA.clientId.toString() === '4367' || this.state.USER_DATA.clientId.toString() === '5540' || this.state.USER_DATA.clientId.toString() === '4376') {
            if (new Date() < new Date("05/01/2021")) {
                tomorrow.setDate(1);
                tomorrow.setMonth(4);
            } else if (new Date().getDate() === 1 || new Date().getDate() > 1) {
                tomorrow.setDate(1);
                tomorrow.setMonth(today.getMonth() + 1);
            }
        } else {
            tomorrow.setDate(tomorrow.getDate() + 1);
        }
        this.setState({
            addEmployerModal: false,
            errMsg: '',
            // compName: '',
            // zipCode: '',
            // state: '',
            // city: '',
            // street: '',
            // division: '',
            // industry: '',
            // codeTitle: '',
            // code: '',
            // firstName: '',
            // lastName: '',
            // workEmail: '',
            // jobTitle: '',
            // phone: '',
            // industryList: [],
            // codeTitleList: [],
            // codeList: [],
            // countryCode: '',
            // adminPhone: '',
            // adminFirstName: '',
            // adminLastName: '',
            // adminEmail: '',
            // adminCountryCode: '',
            // effectiveDate: new Date(tomorrow),
            employerEdit: false,
            // empid: '',
            copyEmpCheck: false,
            compNameErr: false,
            zipCodeErr: false,
            cityErr: false,
            stateErr: false,
            firstNameErr: false,
            lastNameErr: false,
            workEmailErr: false,
            phoneErr: false,
            isUpdated: false
        })
    }

    setEmpValue = (value, isValid, parentDetails) => {
        // console.log(value.split('').filter(val => val != '%'));
        // value = value.split('').filter(val => val != '%')
        // let newVal = value.join('')

        // console.log('new - ',newVal);
        this.state.isUpdated = true
        if (isValid) {
            if (parentDetails.name == 'contributionType') {
                this.state['employerContribution'] = null;
            }
            if (parentDetails.name == 'employerContribution') {
                // if (this.state.contributionType == 'Doller') {
                //     if (value.length > 1) {
                //         value = value.slice(1)
                //     }
                // }
                // else {
                //     if (value.length > 1) {
                //         value = value.slice(0, -1)
                //     }
                // }
                this.state[parentDetails.name] = value
                // this.state[parentDetails.name] = value ? parseFloat(value) : null;
            } else this.state[parentDetails.name] = value;
            if (parentDetails.name === 'zipCode') {
                this.handleZipCode(value, parentDetails);
            }
        } else {
            this.state[parentDetails.name] = "";
        }
        let errField = [parentDetails.name] + 'Err'
        this.state[errField] = false
        this.setState({
            refresh: true,
            errField: false
        });
        let fields = ['firstName', 'lastName', 'email', 'phone']
        if (fields.some(field => parentDetails.name.toLowerCase().includes(field.toLowerCase()))) {
            this.setState({copyEmpCheck: false})
        }
        this.checkValidFields()
    }

    checkValidFields = () => {
        if ((this.state.compName !== '' && this.state.compName !== null) &&
            (this.state.zipCode !== '' && this.state.zipCode !== null) &&
            (this.state.city !== '' && this.state.city !== null) &&
            (this.state.state !== '' && this.state.state !== null) &&
            this.state.firstName !== '' &&
            this.state.lastName !== '' &&
            this.state.workEmail !== '' &&
            this.state.phone !== '' && this.state.phone.length == 17) {
                if (this.state.isUpdated) {
                    this.setState({ userValid: false })
                } else {
                    this.setState({ userValid: true })
                }
        } else {
            this.setState({ userValid: true })
        }
    }

    handleZipCode = (zipcode, parent) => {
        this.setState({
            loaderShow: true
        });
        let url = `https://secure.shippingapis.com/ShippingAPI.dll?API=CityStateLookup&XML=<CityStateLookupRequest USERID="935USTGL7449"><ZipCode ID="0"><Zip5>${zipcode}</Zip5></ZipCode></CityStateLookupRequest>`

        axios.get(url)
            .then(response => {
                var result2 = convert.xml2json(response.data, { compact: false, spaces: 4 });
                if (JSON.parse(result2).elements[0].elements[0].elements[0].elements[2]) {
                    var evt = new CustomEvent('zip', { detail: { zipcode: zipcode, flag: true, errMsg: "Enter valid zip code", parentDetails: parent } });
                    window.dispatchEvent(evt);
                    this.state.city = '';
                    this.state.state = '';
                    this.state.country = '';
                    this.setState({
                        refresh: true,
                        loaderShow: false
                    }, () => { this.checkValidFields() });

                } else {
                    this.state.zipCode = JSON.parse(result2).elements[0].elements[0].elements[0].elements[0].text;
                    this.state.state = JSON.parse(result2).elements[0].elements[0].elements[2].elements[0].text;
                    this.state.city = JSON.parse(result2).elements[0].elements[0].elements[1].elements[0].text;
                    this.state.country = 'US';
                    this.setState({
                        refresh: true,
                        loaderShow: false
                    }, () => { this.checkValidFields() });
                }
            })
            .catch(error => {
                var evt = new CustomEvent('zip', { detail: { zipcode: zipcode, flag: true, errMsg: "An unexpected error occurred. Please try again later.", parentDetails: parent } });
                window.dispatchEvent(evt);
                this.state.city = '';
                this.state.state = '';
                this.state.country = '';
                this.setState({
                    refresh: true,
                    loaderShow: false
                }, () => { this.checkEnable() });
            })
    };

    setEmpDropDownValue = (value, isValid, parentDetails) => {
        this.setState({
            loaderShow: true
        })
        if (isValid) {
            this.state[parentDetails.name] = value;
        } else {
            this.state[parentDetails.name] = "";
        }

        if (parentDetails.name === 'division') {
            this.setState({
                industryList: [],
                codeTitleList: [],
                code: ''
            })
            let obj = {
                "searchKey": "industry",
                "searchValue": value
            }

            this.getSICDetails(obj);
        } else if (parentDetails.name === 'industry') {
            this.setState({
                codeTitleList: [],
                code: ''
            })
            let obj = {
                searchKey: 'codeTitle',
                searchValue: value
            }
            this.getSICDetails(obj);
        } else if (parentDetails.name === 'codeTitle') {
            let findIndex = this.state.codeTitleList.find(obj => obj.key === value);
            if (findIndex) {
                //this.state.code = findIndex.sicCode;
                this.setState({
                    code: findIndex.sicCode,
                    loaderShow: false
                });
            }
        }
        this.setState({
            refresh: true,
        });
    }

    getSICDetails = (obj) => {
        axios.post(process.env.REACT_APP_BASE_URL + '/employer/getSIC', obj)
            .then(response => {
                let arr = [];
                let data = response.data.response;
                if (obj.searchKey === 'industry') {
                    for (let i = 0; i < data.length; i++) {
                        arr.push({ key: data[i].industry, value: data[i].industry })
                    }
                    this.setState({
                        industryList: arr,
                        loaderShow: false
                    })
                } else if (obj.searchKey === 'codeTitle') {
                    for (let i = 0; i < data.length; i++) {
                        arr.push({ key: data[i].codeTitle, value: data[i].codeTitle, sicCode: data[i].sicCode })
                    }
                    this.setState({
                        codeTitleList: arr,
                        loaderShow: false
                    })
                }
            });
    }

    menuOptions = [
        "Edit Details",
        "Send Purchase Link",
        "Purchase Subscription",
        "Enroll Employees",
    ];

    menuOptions1 = [
        "Edit Details",
        "Send Purchase Link",
        "Purchase Subscription",
        "Enroll Employees",
    ];

    columns = [
        // { label: "User Id", name: "id" },
        {
            label: "Date Added", name: "createdDate", options: {
                sort: true,
                customBodyRenderLite: (index) => {
                    // this.state.dateAdded= (this.state.data[index].createdDate).format('yy-mm-dd');
                    if (this.state.employerList && this.state.employerList[index] && this.state.employerList[index].createdDate) {
                        return moment.utc(this.state.employerList[index].createdDate).format('MMMM DD, YYYY');
                    } else {
                        return "NA";
                    }
                }
            }
        },
        {
            label: "Name", name: "companyName", options: {
                sort: true,
                customBodyRenderLite: (index) => {
                    if (this.state.employerList && this.state.employerList[index] && this.state.employerList[index].companyName) {
                        return this.state.employerList[index].companyName;
                    } else if (this.state.employerList && this.state.employerList[index] && (this.state.employerList[index].firstName || this.state.employerList[index].lastName)) {
                        return this.state.employerList[index].firstName + " " + this.state.employerList[index].lastName;
                    } else {
                        return 'NA';
                    }
                }
            }
        },
        {
            label: "Enrolled Subscriptions", name: "enrolledQuantity", options: {
                sort: true,
                customBodyRenderLite: (index) => {
                    if (this.state.employerList && this.state.employerList[index] && this.state.employerList[index].enrolledQuantity) {
                        return this.state.employerList[index].enrolledQuantity;
                    }
                }
            }
        },
        {
            label: "Total Subscriptions", name: "totalQuantity", options: {
                sort: true,
                customBodyRenderLite: (index) => {
                    if (this.state.employerList && this.state.employerList[index] && this.state.employerList[index].totalQuantity) {
                        return this.state.employerList[index].totalQuantity;
                    }
                }
            }
        },
        // {
        //     label: "Status ", name: "status", options: {
        //         sort: true,
        //         customBodyRenderLite: (index) => {
        //             if (this.state.employerList && this.state.employerList[index] && this.state.employerList[index]) {
        //                 return (
        //                     this.state.employerList[index].acknowledgement ?
        //                         <div>
        //                             <text style={{ backgroundColor: '#edf6ee', color: '#37833b', fontSize: 12, padding: '5px 10px', borderRadius: 4 }}>ENROLLED</text>
        //                         </div>
        //                         :
        //                         <div>
        //                             <text style={{ backgroundColor: '#feedec', color: '#c8372d', fontSize: 12, padding: '5px 10px', borderRadius: 4 }}>PENDING</text>
        //                         </div>
        //                 )
        //             }
        //         }
        //     }
        // },
        {
            name: "Action", options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <span >
                            <PopupState variant="popover" popupId="demo-popup-menu" >
                                {(popupState) => (
                                    <React.Fragment>
                                        <IconButton {...bindTrigger(popupState)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu {...bindMenu(popupState)} onClick={popupState.close}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center'
                                            }}
                                            transformOrigin={{
                                                vertical: -10,
                                                horizontal: 30
                                            }}
                                        >
                                            {
                                                this.state.employerList && this.state.employerList[dataIndex] &&
                                                    this.state.employerList[dataIndex].acknowledgement ?
                                                    <div>
                                                        {
                                                            this.menuOptions1.map((option, index) => {
                                                                return <MenuItem key={index} value='view' onClick={(e) => this.selectOption(index, dataIndex)}  >{option}</MenuItem>
                                                            })
                                                        }
                                                    </div> :
                                                    <div>
                                                        {
                                                            this.menuOptions.map((option, index) => {
                                                                return <MenuItem key={index} value='view' onClick={(e) => this.selectOption(index, dataIndex)}  >{option}</MenuItem>
                                                            })
                                                        }
                                                    </div>
                                            }
                                        </Menu>
                                    </React.Fragment>
                                )}
                            </PopupState>

                        </span>
                    );
                }
            }
        }
    ];

    selectOption = async (index, dataIndex) => {
        if (index === 0) {
            const { companyName, zip, state, city, street, division, industry, codeTitle, code, firstName, lastName, email, jobTitle, phone, effective_date, empid } = this.state.employerList[dataIndex];
            let obj = {
                "searchKey": "industry",
                "searchValue": division
            }
            this.getSICDetails(obj);
            let newObj = {
                "searchKey": "codeTitle",
                "searchValue": industry
            }
            this.getSICDetails(newObj);
            let phoneNumber = '';
            let countryCode = '';

            if (phone && phone.length > 0) {
                if (phone.length === 13) {
                    countryCode = phone.substr(0, 3);
                    phoneNumber = this.formatPhoneNumber(phone.substr(2));
                    // phoneNumber = phone.substr(3);
                } else {
                    countryCode = phone.substr(0, 2);
                    phoneNumber = this.formatPhoneNumber(phone);
                    // phoneNumber = phone.substr(2);
                }
            } else {
                axios.get('https://ipapi.co/json/').then((response) => {
                    if (response && response.data) {
                        let data = response.data;
                        countryCode = data.country_calling_code;
                        phoneNumber = phone;
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }


            // if (phone) {

                axios.get(process.env.REACT_APP_BASE_URL + '/employer/getEmployerByEmpId/' + empid)
                    .then(response => {
                        console.log(response);
                        if (response.data.response) {
                            let phone = '';
                            if (response.data.response.phone) {
                                if (response.data.response.phone.length === 13) {
                                    phone = this.formatPhoneNumber(response.data.response.phone.substr(2));
                                } else {
                                    phone = this.formatPhoneNumber(response.data.response.phone);
                                }
                            }
                            this.setState({
                                id: response.data.response.id,
                                compName: response.data.response.companyName,
                                firstName: response.data.response.firstName,
                                lastName: response.data.response.lastName,
                                email: response.data.response.email,
                                phone: phone,
                                zipCode: response.data.response.zip,
                                state: response.data.response.state,
                                city: response.data.response.city,
                                jobTitle: response.data.response.jobTitle,
                                oldEmail: response.data.response.email,
                                workEmail: response.data.response.email,
                                street: response.data.response.street,
                                division: response.data.response.division,
                                industry: response.data.response.industry,
                                codeTitle: response.data.response.codeTitle,
                                code: response.data.response.code,
                                // effectiveDate: response.data.response.effective_date ? moment(response.data.response.effective_date).format('MMMM DD, YYYY') : null,
                                employerContribution: response.data.response.employerContribution,
                                payrollCycle: response.data.response.payrollCycle || 'Weekly',
                                contributionType: response.data.response.contributionType || 'Doller',
                                bankName: response.data.response.employerPayment?.bankName,
                                accountName: response.data.response.employerPayment?.accountName,
                                accountNumber: response.data.response.employerPayment?.accountNumber,
                                accountType: response.data.response.employerPayment?.accountType,
                                routingNo: response.data.response.employerPayment?.routingNo,
                                employerEdit: true,
                                addEmployerModal: true,
                                empid,
                                sendAuthEmail: response.data.response.email,
                                acknowledged: response.data.response.acknowledgement
                            }, () => this.checkValidFields())
                            let arr = [];
                            // if(response.data.response.email == response.data.response.adminEmail){
                            //     arr.push({key : response.data.response.email, value : response.data.response.email});
                            // }else{
                            //     arr.push({key : response.data.response.email, value : response.data.response.email});
                            //     arr.push({key : response.data.response.adminEmail, value : response.data.response.adminEmail});
                            // }
                            if (response.data.response.email == response.data.response.adminEmail) {
                                arr.push({ key: sessionStorage.getItem('email') + " " + "(Agent)", value: sessionStorage.getItem("email") });
                                arr.push({ key: response.data.response.email ? response.data.response.email + " " + "(Employer/Administrator)" : '', value: response.data.response.email ? response.data.response.email : '' });

                            } else {
                                arr.push({ key: sessionStorage.getItem('email') + " " + "(Agent)", value: sessionStorage.getItem("email") });
                                arr.push({ key: response.data.response.email + ' ' + "(Employer)", value: response.data.response.email });
                                arr.push({ key: response.data.response.adminEmail ? response.data.response.adminEmail + " " + "(Administrator)" : '', value: response.data.response.adminEmail ? response.data.response.adminEmail : '' });

                            }

                        }
                    })
            // }
            if (index === 5) {  //open employee
                console.log(window.location);
                axios.get(process.env.REACT_APP_BASE_URL + '/employer/getEmployerByEmpId/' + this.state.employerList[dataIndex].empid)
                    .then(response => {
                        console.log(response);
                        if (response.data.response) {
                            if (response.data.response.acknowledgement) {
                                window.location.replace(window.location.origin + '/manage-prospects?empid=' + this.state.employerList[dataIndex].empid)
                            } else {
                                this.setState({
                                    acknowledgement: response.data.response.acknowledgement,
                                    showAcknowledgementModal: true,
                                    sendAuthEmail: this.state.employerList[dataIndex].email
                                })
                            }
                        }
                    })

            }
            if (index === 6) {
                let obj = [
                    {
                        key: 'Company Name',
                        value: this.state.employerList[dataIndex].companyName
                    },
                    {
                        key: 'Name',
                        value: this.state.employerList[dataIndex].firstName + ' ' + this.state.employerList[dataIndex].lastName
                    },
                    {
                        key: 'Email id',
                        value: this.state.employerList[dataIndex].email
                    },
                    {
                        key: 'Phone Number',
                        value: this.state.employerList[dataIndex].phone ? this.state.employerList[dataIndex].phone : 'NA'
                    },
                    {
                        key: 'Zip Code',
                        value: this.state.employerList[dataIndex].zip
                    },
                    {
                        key: 'State',
                        value: this.state.employerList[dataIndex].state
                    },
                    {
                        key: 'Street, Suit',
                        value: this.state.employerList[dataIndex].street
                    },
                    {
                        key: 'Division',
                        value: this.state.employerList[dataIndex].division
                    },
                    {
                        key: 'Industry',
                        value: this.state.employerList[dataIndex].industry
                    },
                    {
                        key: 'SIC Code Title',
                        value: this.state.employerList[dataIndex].codeTitle
                    },
                    {
                        key: 'SIC Code',
                        value: this.state.employerList[dataIndex].code
                    },
                    {
                        key: "Effective Date",
                        value: this.state.employerList[dataIndex].effective_date ? moment(this.state.employerList[dataIndex].effective_date).format('MMMM DD, YYYY') : null
                    },
                ]



                this.setState({
                    employerDetailsObj: obj,
                    viewEmployerModal: true
                })
            } else {
                axios.get('https://ipapi.co/json/').then((response) => {
                    if (response && response.data) {
                        let data = response.data;
                        axios.get(process.env.REACT_APP_BASE_URL + '/employer/getEmployerByEmpId/' + empid)
                            .then(response => {
                                console.log(response);
                                if (response.data.response) {
                                    let phone = '';
                                    if (response.data.response.phone) {
                                        if (response.data.response.phone.length === 13) {
                                            phone = this.formatPhoneNumber(response.data.response.phone.substr(2));
                                        } else {
                                            phone = this.formatPhoneNumber(response.data.response.phone);
                                        }
                                    }
                                    this.setState({
                                        id: response.data.response.id,
                                        compName: response.data.response.companyName,
                                        firstName: response.data.response.firstName,
                                        lastName: response.data.response.lastName,
                                        email: response.data.response.email,
                                        phone: phone,
                                        zipCode: response.data.response.zip,
                                        state: response.data.response.state,
                                        city: response.data.response.city,
                                        jobTitle: response.data.response.jobTitle,
                                        workEmail: response.data.response.email,
                                        street: response.data.response.street,
                                        division: response.data.response.division,
                                        industry: response.data.response.industry,
                                        codeTitle: response.data.response.codeTitle,
                                        code: response.data.response.code,
                                        // effectiveDate: response.data.response.effective_date ? moment(response.data.response.effective_date).format('MMMM DD, YYYY') : null,
                                        adminCountryCode: data.country_calling_code,
                                        employerContribution: response.data.response.employerContribution,
                                        payrollCycle: response.data.response.payrollCycle || 'Weekly',
                                        contributionType: response.data.response.contributionType || 'Doller',
                                        bankName: response.data.response.employerPayment?.bankName,
                                        accountName: response.data.response.employerPayment?.accountName,
                                        accountNumber: response.data.response.employerPayment?.accountNumber,
                                        accountType: response.data.response.employerPayment?.accountType,
                                        routingNo: response.data.response.employerPayment?.routingNo,
                                        employerEdit: true,
                                        addEmployerModal: true,
                                        empid,
                                        sendAuthEmail: response.data.response.email,
                                        acknowledged: response.data.response.acknowledgement
                                    }, () => this.checkValidFields())
                                    let arr = [];
                                    // if(response.data.response.email == response.data.response.adminEmail){
                                    //     arr.push({key : response.data.response.email, value : response.data.response.email});
                                    // }else{
                                    //     arr.push({key : response.data.response.email, value : response.data.response.email});
                                    //     arr.push({key : response.data.response.adminEmail, value : response.data.response.adminEmail});
                                    // }
                                    if (response.data.response.email == response.data.response.adminEmail) {
                                        arr.push({ key: sessionStorage.getItem('email') + " " + "(Agent)", value: sessionStorage.getItem("email") });
                                        arr.push({ key: response.data.response.email ? response.data.response.email + " " + "(Employer/Administrator)" : '', value: response.data.response.email ? response.data.response.email : '' });

                                    } else {
                                        arr.push({ key: sessionStorage.getItem('email') + " " + "(Agent)", value: sessionStorage.getItem("email") });
                                        arr.push({ key: response.data.response.email + ' ' + "(Employer)", value: response.data.response.email });
                                        arr.push({ key: response.data.response.adminEmail ? response.data.response.adminEmail + " " + "(Administrator)" : '', value: response.data.response.adminEmail ? response.data.response.adminEmail : '' });

                                    }
                                }
                            })
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
        if (index === 1) {
            console.log(this.state.employerList[dataIndex]);
            this.setState({
                employerEmail: this.state.employerList[dataIndex].email,
                empId: this.state.employerList[dataIndex].empid,
                launchUrl: null,
                // showTrialModal: true
            }, () => this.launchPortal())
            // window.open(process.env.REACT_APP_PURCHASE_WIZARD);
        }
        if (index === 2) {
            this.setState({
                employerEmail: this.state.employerList[dataIndex].email,
                launchUrl: process.env.REACT_APP_PURCHASE_WIZARD,
                // showTrialModal: true
            }, () => this.launchPortal())
        }
        if (index === 3) {
            console.log(this.state.employerList[dataIndex]);
            const token = await CuramgetGateWayToken();
            const data = {
                "cognitoUserPool": process.env.REACT_APP_EMPLOYER_USERPOOL,
                "username": this.state.employerList[dataIndex].email
            }
            axios.post(process.env.REACT_APP_csr_base_url + 'memberportal/getuser', data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(res => {
                    // if (res.data.userStatus == "CONFIRMED") { // check for registration
                    window.open(`${process.env.REACT_APP_EMPLOYER_PORTAL}?ch=${btoa(this.state.USER_DATA.clientId)}&ag=${btoa(this.state.USER_DATA.agentId)}&emp=${btoa(this.state.employerList[dataIndex].email)}&mode=non-trial`);
                    // } else {
                    //     this.setState({ userModal: true, userMsg: 'The employer does not have an active CuramLife subscription. Hence you cannot enroll employees.' })
                    // }
                })
                .catch(err => {
                    this.setState({ userModal: true, userMsg: 'The employer does not have an active CuramLife subscription. Hence you cannot enroll employees.' })
                })
        }
    }

    launchPortal = () => {
        if (this.state.launchUrl) {
            window.open(`${this.state.launchUrl}?ch=${btoa(this.state.USER_DATA.clientId)}&ag=${btoa(this.state.USER_DATA.agentId)}&emp=${btoa(this.state.employerEmail)}&mode=non-trial`);
        } else {
            axios.get(`${process.env.REACT_APP_BASE_URL}/employer/sendEmployerPurchasewizard/${this.state.empId}/non-trial`)
            .then(res => {
                console.log('res');
                if (res.data.code == 200) {
                    this.setState({ userModal: true, userMsg: `Purchase Wizard link email has been sent successfully to <b>${this.state.employerEmail}</b>!` })
                } else {
                    this.setState({ userModal: true, userMsg: `Could not send an email!` })
                }
            })
        }
        this.setState({ showTrialModal: false, employerMode: 'trial' })
    }

    formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }
    handleChangeSearchType(event) {
        let value = event.target.value
        if (value === '' || value === null) {
            this.setState({ searchShow: true, searchText: '', page: 0 }, () => {
                this.searchRecord();
            });
        } else {
            if (this.state.searchTypeValue === '0') {
                formData.set("columns[1].search.value", "");
            } else if (this.state.searchTypeValue === '1') {
                formData.set("columns[3].search.value", "");
            }
            this.setState({ searchTypeValue: value, searchShow: false, searchText: '', page: 0 }, () => {
                this.searchRecord();
            });
        }
    }

    setSearch(event) {
        let value = event.target.value;
        this.setState({
            searchText: value
        }, () => {
            if (this.state.searchText === "" || this.state.searchText === null) {
                this.searchRecord();
            }
        });
    }

    searchRecord = () => {
        this.setState({
            loaderShow: true
        });

        if (this.state.searchTypeValue === '0') { //Date search
            formData.set("start", "0");
            console.log('--- - -- -- - ', requestOptions);
            formData.set("columns[1].search.value", this.state.searchText);

            let URL = `${process.env.REACT_APP_BASE_URL}/employer/getEmployersByAgentId/${this.state.USER_DATA.agentId}?pageNumber=${this.state.page}&pageSize=${this.state.rowsPerPage}`

                axios.get(URL)
                
                .then(data => {
                    if (data.data.response && data.data.response.data) {
                        this.setState({ employerList: data.data.response.data, count: data.response.recordsFiltered, statusValue: '', loaderShow: false })
                    } else {
                        this.setState({ loaderShow: false });
                    }

                });
        } else if (this.state.searchTypeValue === '1') { //Company name search
            formData.set("start", "0");
            formData.set("length", this.state.rowsPerPage.toString());
            formData.set("columns[3].search.value", this.state.searchText);

            let URL = `${process.env.REACT_APP_BASE_URL}/employer/getEmployersByAgentId/${this.state.USER_DATA.agentId}?pageNumber=${this.state.page}&pageSize=${this.state.rowsPerPage}`

            axios.get(URL)
                
                .then(data => {
                    if (data.data.response && data.data.response.data) {
                        this.setState({ employerList: data.data.response.data, count: data.response.recordsFiltered, targetDate: null, statusValue: '', loaderShow: false })
                    } else {
                        this.setState({ loaderShow: false });
                    }

                });
        }
    }

    handleDate(event) {
        this.setState({
            targetDate: moment(event).format('YYYY') + '-' + moment(event).format('MM') + '-' + moment(event).format('DD'),
            searchText: moment(event).format('YYYY') + '-' + moment(event).format('MM') + '-' + moment(event).format('DD'),
        });
    }

    refreshEmployer = () => {
        this.setState({
            loaderShow: true
        });
        this.getEmployerData();
    }

    handleDateChange = (date, didMount) => {
        this.setState({
            effectiveDate: date
        }
            , () => {
                let panel = document.getElementById("date-picker-dialog");
                panel.addEventListener("onmouseleave", function () {
                    document.getElementById("date-picker-dialog-label").style.paddingTop = "10px";
                });
            }
        );
    }

    disableWeekends(date, clientId) {
        // return (clientId.toString() === '6548' || clientId.toString() === '4367' || clientId.toString() === '5540' || clientId.toString() === '4376'|| clientId.toString() === '5541' || clientId.toString() === '4377') ? (date.getDate() === 1 ? false : true) : false;
        // if (clientId.toString() == '1004' || clientId.toString() == '1005' || clientId.toString() == '1006' || clientId.toString() == '1007' || clientId.toString() == '1008' || clientId.toString() == '1010' || clientId.toString() == '1011' || clientId.toString() == '1012'){
        if (date.getDate() === 1) {
            return false
        } else {
            return true
        }
        // }else{
        //     return (clientId === '6548' || clientId === '4367' || clientId === '5540' || clientId === '4376' || clientId === '5541' || clientId === '4377' ) ? (date.getDate() === 1 ? false : true) : false;
        // }
    }

    sendAuth = () => {
        this.setState({
            loaderShow: true,
        })
        axios.get(configurations.baseUrl + `/employer/employerRegistration/${this.state.sendAuthEmail}`)
            .then(response => {
                console.log('empres', response);
                if (response.data.code === 200) {
                    this.setState({
                        loaderShow: false,
                        showAcknowledgementModal: false,
                        authSent: true
                    }, () => this.closeEmployerModal())
                }
            })
    }

    handlePhoneNumberChange = (value, countryData) => {
        const countryCode = `+1`;

        if (value.startsWith(countryCode)) {
            this.setState({ phone: value }, () => this.checkValidFields());
        } else {
            value = value.replace("+", "")
            let phoneNumber = countryCode + value
            let cleanedNumber = phoneNumber.replace(/\D/g, "");
            let formattedNumber = `+${cleanedNumber.substring(0, 1)} (${cleanedNumber.substring(1, 4)}) ${cleanedNumber.substring(4, 7)}-${cleanedNumber.substring(7)}`;
            this.state.phone = formattedNumber
            this.setState({ isUpdate: true }, () => this.checkValidFields());
        }
        this.state.isUpdated = true
    };

    handleKeyDown = (event, countryData) => {
        const countryCode = `+1`;
        const countryCodeLength = countryCode.length;
        const selectionStart = event.target.selectionStart;

        if (event.key === 'Backspace' && selectionStart <= countryCodeLength) {
            event.preventDefault();
            if (selectionStart === countryCodeLength) {
                event.target.setSelectionRange(0, 0);
            }
        }
    };

    render() {
        console.log(this.state.employerContribution, ' - - - - ');
        const options = {
            selectableRows: false,
            filter: false,
            selectableRowsHeader: false,
            count: this.state.count,
            rowsPerPage: this.state.rowsPerPage,
            rowsPerPageOptions: [10, 25, 100],
            search: false,
            serverSide: true,
            download: false,
            viewColumns: false,
            print: false,
            fixedHeader: true,
            page: this.state.page,
            onTableChange: (action, tableState) => {
                switch (action) {
                    case 'changePage':
                        this.changePage(tableState.page, tableState.rowsPerPage);
                        break;
                    case 'changeRowsPerPage':
                        this.changePage(tableState.page, tableState.rowsPerPage);
                        break;
                    default:
                        break;
                }
            },
            onColumnSortChange: (changedColumn, direction) => {
                this.setState({
                    loaderShow: true
                });
                let order = 'desc';
                if (direction === 'asc') {
                    order = 'asc';
                }
                let columnIndex = '0';
                if (changedColumn === "createdDate") {
                    columnIndex = '1';
                } else if (changedColumn === 'email') {
                    columnIndex = '2';
                } else if (changedColumn === 'firstName') {
                    columnIndex = '4';
                }
                this.sort(columnIndex, order);
            },
            onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
                this.state.rowIndex = rowsSelected;
            },
            onCellClick: (cellIndex, rowIndex, rowMeta) => {
                console.log('cellIndex======', cellIndex);
                console.log('rowIndex======', rowIndex.colIndex);

                // if (rowIndex.colIndex !== 3) {

                //     this.setState({
                //         // loaderShow: true
                //     });
                //     console.log(window.location);

                //     axios.get(process.env.REACT_APP_BASE_URL + '/employer/getEmployerByEmpId/' + this.state.employerList[rowIndex.dataIndex].empid)
                //         .then(response => {
                //             console.log(response);
                //             if (response.data.response) {
                //                 if (response.data.response.acknowledgement) {
                //                     window.location.replace(window.location.origin + '/manage-prospects?empid=' + this.state.employerList[rowIndex.dataIndex].empid)
                //                 } else {
                //                     this.setState({
                //                         acknowledgement: response.data.response.acknowledgement,
                //                         showAcknowledgementModal: true,
                //                         sendAuthEmail: this.state.employerList[rowIndex.dataIndex].email
                //                     })
                //                 }
                //             }
                //         })
                // }
            },

            // onRowClick: (rowData, rowMeta) => {
            //     console.log('========== onRowClick ============');
            //     console.log(rowData);
            //     console.log(rowMeta.dataIndex);
            //     console.log(this.state.employerList[rowMeta.dataIndex].empid);
            //     if(this.state.cellFlag==false || this.state.cellFlag=='false'){
            //     this.setState({
            //         loaderShow : true
            //     });
            //     console.log(window.location);
            //     window.location.replace( window.location.origin  + '/manage-prospects?empid=' + this.state.employerList[rowMeta.dataIndex].empid)
            // }
            // }

        };

        // let myDate = moment(this.state.effectiveDate).format('MM') + '/' + moment(this.state.effectiveDate).format('DD') + '/' + moment(this.state.effectiveDate).format('YYYY');
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        let futureTomarow = new Date(today);
        //const futureDate = this.state.USER_DATA.clientId.toString() === '6548' ? futureTomarow.setDate(futureTomarow.getDate() + 45) : futureTomarow.setDate(futureTomarow.getDate() + 90);
        let futureDate = new Date(today);
        if (this.state.USER_DATA.clientId === 1004 || this.state.USER_DATA.clientId === '1004' || this.state.USER_DATA.clientId === 1005 || this.state.USER_DATA.clientId === '1005' || this.state.USER_DATA.clientId === 1006 || this.state.USER_DATA.clientId === '1006' || this.state.USER_DATA.clientId === 1007 || this.state.USER_DATA.clientId === '1007' || this.state.USER_DATA.clientId === 1008 || this.state.USER_DATA.clientId === '1008' || this.state.USER_DATA.clientId === 1010 || this.state.USER_DATA.clientId === '1010' || this.state.USER_DATA.clientId === 1011 || this.state.USER_DATA.clientId === '1011' || this.state.USER_DATA.clientId === 1012 || this.state.USER_DATA.clientId === '1012') {
            if (new Date() < new Date("05/01/2021")) {
                futureTomarow.setDate(1);
                futureTomarow.setMonth(4);
                tomorrow.setDate(1);
                tomorrow.setMonth(4);
            } else if (new Date().getDate() === 1 || new Date().getDate() <= 15) {
                futureTomarow.setDate(1);
                futureTomarow.setMonth(today.getMonth() + 2);
                tomorrow.setDate(1);
                tomorrow.setMonth(today.getMonth() + 2);
                futureDate = futureTomarow.setDate(futureTomarow.getDate() + 45)
            } else if (new Date().getDate() > 15) {
                futureTomarow.setDate(15);
                futureTomarow.setMonth(today.getMonth() + 2);
                futureDate = futureTomarow.setDate(futureTomarow.getDate() + 45);
                tomorrow.setDate(15);
                tomorrow.setMonth(today.getMonth() + 2);
            }
        } else if (new Date().getDate() > 15) {
            // futureDate = futureTomarow.setDate(futureTomarow.getDate() + 90);
            futureDate.setDate(1);
            futureDate.setMonth(today.getMonth() + 2);
        } else {
            // tomorrow.setDate(tomorrow.getDate() + 1);
            futureDate.setDate(15);
            futureDate.setMonth(today.getMonth() + 1);
        }

        return (

            <div>
                <Header agentId={this.state.USER_DATA.agentId} clientId={this.state.USER_DATA.clientId} associationId={this.state.USER_DATA.associationId} clientName={this.state.USER_DATA.clientName} bottomMargin={'0px'}></Header>
                <div style={{ flexGrow: 1 }}>
                    <Grid container direction="row" style={{ marginBottom: '20px', testAlign: 'center' }}>
                    
                         <Grid xs={12} sm={12} item style={{fontSize : '16px', fontWeight : 'bold', marginLeft : '20px', marginTop: '20px' }}>
                             Hello {sessionStorage.getItem('USER_NAME')}
                         </Grid>
                    
                        {/* <Grid xs={12} sm={12} item={true} style={{ backgroundColor: '#41b5c2', color: '#ffffff', fontWeight: 'bold', height: '40px', fontSize: '16px', padding: '10px' }}>
                            <div style={{ cursor: 'pointer', width: '7%' }} onClick={() => window.location.replace('/')}>
                                <ArrowBackIcon /> BACK
                            </div>
                        </Grid> */}
                    </Grid>
                </div>
                <div style={{ flexGrow: 1, paddingLeft: '10px', minHeight: '100vh' }}>
                    <Grid container spacing={2} justify='center'>
                        <Grid xs={12} sm={8} item={true} style={{}}>
                            <div style={{ height: 'fit-content', backgroundColor: '#ffffff' }}>
                                <div>
                                    {
                                        this.state.loaderShow ? <Loader></Loader> : ''
                                    }
                                    <div style={{ flexGrow: 1 }}>
                                        <Grid container style={{ padding: '10px' }}>
                                        
                                            <Grid item xs={3} sm={3}>
                                                <Typography style={{ fontSize: '21px', fontWeight: 'bold', padding: '10px' }}> Active Employers</Typography>
                                            </Grid>

                                            <Grid item xs={3} sm={3}>
                                                {/* <FormControl style={customStyle.formControlStyle}>
                                                    <InputLabel style={{ textAlign: 'left' }}
                                                    >Search By</InputLabel>
                                                    <Select
                                                        label="Search By"
                                                        name="name"
                                                        value={this.state.searchTypeValue}
                                                        onChange={(event) => this.handleChangeSearchType(event)}
                                                    >
                                                        <MenuItem value='0'>Date Added</MenuItem>
                                                        <MenuItem value='1'>Company Name</MenuItem>
                                                    </Select>
                                                </FormControl> */}
                                            </Grid>
                                            <Grid item xs={3} sm={3} >

                                                {/* {
                                                    this.state.searchTypeValue === '0' ?

                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                autoComplete='off'
                                                                margin="none"
                                                                label="Select Date"
                                                                format="MMMM dd,yyyy"
                                                                value={this.state.targetDate}
                                                                onFocus={e => e.target.blur()}
                                                                inputProps={{
                                                                    style: {
                                                                        fontSize: '12.8px', fontFamily: "Roboto, Arial, Helvetica, sans-serif", marginTop: '3px',
                                                                    }
                                                                }}
                                                                InputLabelProps={{ style: { paddingRight: 10, paddingTop: 0, color: 'grey' } }}
                                                                onChange={(event) => this.handleDate(event)}
                                                                variant="filled"
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                                views={["year", "month", "date"]}
                                                                openTo="year"
                                                                minDate={new Date('01/01/2020')}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                        :
                                                        <TextField style={{ marginTop: '15px' }}
                                                            value={this.state.searchText}
                                                            disabled={this.state.searchShow}
                                                            onChange={(event) => this.setSearch(event)}></TextField>

                                                } */}

                                            </Grid>
                                            <Grid item xs={1} sm={1}>
                                                {/* <Tooltip
                                                    title="Search"
                                                    placement="bottom"
                                                >
                                                <CrudButton color="secondary" style={{ marginTop: '7px', boxShadow: 'none', width: '46px', height: '46px' }} aria-label="search" disabled={this.state.searchText == '' || this.state.targetDate == ''} onClick={() => this.searchRecord()} >
                                                    <SearchIcon />
                                                </CrudButton>
                                                </Tooltip> */}
                                            </Grid>
                                            <Grid item xs={1} sm={1}>
                                                <Tooltip
                                                    title="Add Employer"
                                                    placement="bottom"
                                                >
                                                    <CrudButton color="primary" aria-label="add" style={{ width: '46px', height: '46px', boxShadow: 'none', marginTop: '7px', marginLeft: '3%', backgroundColor: '#00c65e' }} onClick={() => this.addEmployerModal()} >
                                                        <AddIcon />
                                                    </CrudButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={1} sm={1}>
                                                <Tooltip
                                                    title="Refresh"
                                                    placement="bottom"
                                                >
                                                    <CrudButton style={{ boxShadow: 'none', width: '46px', height: '46px', marginTop: '7px', backgroundColor: '#009cde' }} onClick={() => { this.refreshEmployer() }}><RefreshIcon /></CrudButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <MuiThemeProvider theme={getMuiTheme()}>
                                        <MUIDataTable
                                            /*title={' Active Employers'}*/
                                            data={this.state.employerList}
                                            columns={this.columns}
                                            options={options}
                                        />
                                    </MuiThemeProvider>
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={4} item={true}>
                            <AccordonCommon clientId={this.state.USER_DATA.clientId} clientName={this.state.USER_DATA.clientName} agentId={this.state.USER_DATA.agentId} disable={true} forHouseholds={false} refreshTable={() => this.getEmployerData()} />
                        </Grid>
                    </Grid>
                </div>

                <Modal size="lg" show={this.state.addEmployerModal} onHide={(event) => this.closeEmployerModal()} centered backdrop='static'>
                    <Modal.Header style={customStyle.modal_header} closeButton>
                        <Modal.Title>{this.state.employerEdit ? "Edit Employer" : "Add Employer"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: '330px', overflow: 'auto' }}>
                        {
                            this.state.loaderShow ? <Loader></Loader> : ''
                        }
                        <div style={{ flexGrow: 1 }}>
                            <Grid container spacing={2} justify="center" >
                                <Grid item xs={12} sm={10}>
                                    <Sample isError={this.state.compNameErr} setChild={this.setEmpValue.bind(this)} reqFlag={true} name={'CompanyName'} label={'Company Name'} value={this.state.compName} disable={false} style={customStyle.textFieldWrpAgent} length={50} fieldType={'company_name'} errMsg={'Enter valid company name'} helperMsg={'Company name required'} parentDetails={{ name: 'compName' }}></Sample>
                                </Grid>
                                <Grid item xs={12} sm={10} style={customStyle.sectionPart}>
                                    <span style={customStyle.textBold}>Address Details</span>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Sample isError={this.state.zipCodeErr} setChild={this.setEmpValue.bind(this)} reqFlag={true} name={'zipCode'} label={'Zip Code'} value={this.state.zipCode} disable={false} style={customStyle.textFieldWrpAgent} length={6} fieldType={'zip'} errMsg={'Enter valid zip code'} helperMsg={'Zip code required'} parentDetails={{ name: 'zipCode' }}></Sample>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Sample isError={this.state.cityErr} setChild={this.setEmpValue.bind(this)} reqFlag={true} name={'city'} label={'City'} value={this.state.city} disable={true} style={customStyle.textFieldWrpAgent} length={5} fieldType={'city'} errMsg={'Enter valid city'} helperMsg={'City required'} parentDetails={{ name: 'city' }}></Sample>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Sample isError={this.state.stateErr} setChild={this.setEmpValue.bind(this)} reqFlag={true} name={'state'} label={'State'} value={this.state.state} disable={true} style={customStyle.textFieldWrpAgent} length={25} fieldType={'city'} errMsg={'Enter valid state'} helperMsg={'State required'} parentDetails={{ name: 'state' }}></Sample>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Sample setChild={this.setEmpValue.bind(this)} reqFlag={false} name={'street'} label={'Street, Suite'} value={this.state.street} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'street'} errMsg={'Enter valid street'} parentDetails={{ name: 'street' }}></Sample>
                                </Grid>

                                {/* <Grid item xs={12} sm={5}>
                                    <span>Payroll Cycle</span>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <span>Employer Contribution</span>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <div>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                row
                                                aria-label="days"
                                                name="row-radio-buttons-group"
                                                value={this.state.payrollCycle}
                                                onChange={(event) => this.setState({payrollCycle: event.target.value})}
                                            >
                                                <FormControlLabel
                                                    value="Weekly"
                                                    control={<PurpleRadio />}
                                                    label="Weekly"
                                                />
                                                <FormControlLabel
                                                    value="BiWeekly"
                                                    control={<PurpleRadio />}
                                                    label="Bi-Weekly"
                                                />
                                                <FormControlLabel
                                                    value="Monthly"
                                                    control={<PurpleRadio />}
                                                    label="Monthly"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Sample setChild={this.setEmpValue.bind(this)} name={'employerContribution'} label={'Employer Contribution'} value={this.state.employerContribution} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'employerContribution'} errMsg={'Enter valid data'} helperMsg={'Employer Contribution required'} parentDetails={{ name: 'employerContribution' }}></Sample>
                                </Grid> */}

                                {/* <Grid item xs={12} sm={10}>
                                    <span>SIC</span>
                                </Grid>
                                <Grid item xs={12} sm={5} style={{ width: '90%' }}>
                                    <CommonDropDwn setChild={this.setEmpDropDownValue.bind(this)} reqFlag={false} name={'division'} label={'Division'} value={this.state.division} fieldType={'dropDwn'} disable={false} style={customStyle.dropDown} List={this.state.divisionList} errMsg={'Select valid division'}  parentDetails={{ name: 'division' }}></CommonDropDwn>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <CommonDropDwn setChild={this.setEmpDropDownValue.bind(this)} reqFlag={false} name={'industry'} label={'Industry'} value={this.state.industry} disable={this.state.industryList.length === 0} style={customStyle.textFieldWrpAgent} List={this.state.industryList} errMsg={'Select valid industry'}  parentDetails={{ name: 'industry' }}></CommonDropDwn>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <CommonDropDwn setChild={this.setEmpDropDownValue.bind(this)} reqFlag={false} name={'codeTitle'} label={'SIC Code Title'} value={this.state.codeTitle} disable={this.state.codeTitleList.length === 0} style={customStyle.textFieldWrpAgent} List={this.state.codeTitleList} errMsg={'Select valid code title'}  parentDetails={{ name: 'codeTitle' }}></CommonDropDwn>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Sample setChild={this.setEmpValue.bind(this)} reqFlag={false} name={'sicCode'} label={'SIC Code'} value={this.state.code} disable={true} style={customStyle.textFieldWrpAgent} length={10} fieldType={'num'} errMsg={'Enter valid code'}  parentDetails={{ name: 'code' }}></Sample>
                                </Grid> */}
                                <Grid item xs={12} sm={10} style={customStyle.sectionPart}>
                                    <span style={customStyle.textBold}>Employer Contact Details</span>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <SampleName setChild={this.setEmpValue.bind(this)} isError={this.state.firstNameErr} reqFlag={true} name={'fistName'} label={'First Name'} value={this.state.firstName} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'text'} errMsg={'Enter valid first name'} helperMsg={'First name required'} parentDetails={{ name: 'firstName' }}></SampleName>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <SampleName setChild={this.setEmpValue.bind(this)} isError={this.state.lastNameErr} reqFlag={true} name={'lastName'} label={'Last Name'} value={this.state.lastName} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'lastName'} errMsg={'Enter valid last name'} helperMsg={'Last name required'} parentDetails={{ name: 'lastName' }}></SampleName>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Sample setChild={this.setEmpValue.bind(this)} reqFlag={false} name={'jobTitle'} label={'Job title'} value={this.state.jobTitle} disable={false} style={customStyle.textFieldWrpAgent} length={50} fieldType={'company_name'} errMsg={'Enter valid job title'} parentDetails={{ name: 'jobTitle' }}></Sample>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Sample setChild={this.setEmpValue.bind(this)} isError={this.state.workEmailErr} reqFlag={true} name={'workEmail'} label={'Work Email'} value={this.state.workEmail} style={customStyle.textFieldWrpAgent} length={50} fieldType={'email'} errMsg={'Enter valid work email'} helperMsg={'Work email required'} parentDetails={{ name: 'workEmail' }}></Sample>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <div>
                                        <MuiPhoneNumber
                                            key={this.state.isUpdate}
                                            name={"phone"}
                                            required id="filled-required"
                                            label={"Mobile No."}
                                            data-cy="user-phone"
                                            variant="filled"
                                            defaultCountry={"us"}
                                            onlyCountries={["us"]}
                                            countryCodeEditable={false}
                                            autoComplete='off'
                                            value={this.state.phone}
                                            length={17}
                                            onChange={this.handlePhoneNumberChange}
                                            onKeyDown={(event, countryData) => this.handleKeyDown(event, countryData)}
                                            disable={false} style={customStyle.textFieldWrpAgent} fieldType={'phone'}
                                            errMsg={'Enter valid mobile no.'} helperMsg={'Mobile no. required'}
                                        />
                                        {this.state.phone.length == 17 ? null : <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-required mobileErr">Enter valid mobile no.</p>}
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            required
                                            onBlur={() => this.setState({ birthDtFocus: true })}
                                            onMouseOver={() => this.setState({ birthDt: true })}
                                            onMouseLeave={() => this.setState({ birthDt: false })}
                                            autoComplete='off'
                                            margin="none"
                                            id="date-picker-dialog"
                                            label="Select Program Effective Date"
                                            format="MM/dd/yyyy"
                                            error={this.state.dateErr} //&&!this.state.todayDateValid
                                            helperText={this.state.dateErr ? 'Enter valid date' : ''} //this.state.todayDateValid?'Date Required':
                                            value={this.state.effectiveDate} //this.state.todayDateValid?null:
                                            onFocus={e => e.target.blur()}
                                            onCopy={this.handlerCopy}
                                            onPaste={this.handlerCopy}
                                            inputProps={{ style: { fontSize: '18px', fontFamily: 'Roboto, Arial, Helvetica, sans-serif', paddingLeft: '11px', paddingRight: '10px', marginTop: '11px', '&:focus': { outline: 'none' }, color: !this.state.birthDt ? 'grey' : '#533278' } }}
                                            InputLabelProps={{ style: { paddingLeft: 10, paddingRight: 10, paddingTop: 12, color: !this.state.birthDtFocus ? 'grey' : this.state.birthDt ? '#533278' : 'grey' } }}//|| !this.state.todayDateValid
                                            onChange={this.handleDateChange.bind(this)}
                                            variant="filled"
                                            // onMouseEnter={this.handleHover}
                                            TextFieldComponent={CssTextField}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            style={{ width: '100%' }}
                                            shouldDisableDate={(e) => this.disableWeekends(e, this.state.USER_DATA.clientId)}
                                            minDate={this.state.minDate}
                                        // maxDate={new Date(futureDate)}
                                        />
                                        <span id='bd' style={customStyle.EnrollNew2Span}></span>

                                    </MuiPickersUtilsProvider> */}
                                </Grid>
                                {/* <Grid item xs={12} sm={10}>
                                    <span style={{ marginRight: '20px' }}>Health Administrator Contact Details</span>
                                    <Checkbox
                                        checked={this.state.copyEmpCheck}
                                        inputProps={{
                                            'aria-label': 'secondary checkbox',
                                        }}
                                        style={{ color: '#533278' }}
                                        onClick={this.copyEmployerDetails}
                                    />
                                    <span>Same as above</span>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <SampleName setChild={this.setEmpValue.bind(this)} isError={this.state.adminFirstNameErr} reqFlag={true} name={'adminFistName'} label={'First Name'} value={this.state.adminFirstName} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'text'} errMsg={'Enter valid first name'} helperMsg={'First name required'} parentDetails={{ name: 'adminFirstName' }}></SampleName>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <SampleName setChild={this.setEmpValue.bind(this)} isError={this.state.adminLastNameErr} reqFlag={true} name={'adminLastName'} label={'Last Name'} value={this.state.adminLastName} disable={false} style={customStyle.textFieldWrpAgent} length={25} fieldType={'lastName'} errMsg={'Enter valid last name'} helperMsg={'Last name required'} parentDetails={{ name: 'adminLastName' }}></SampleName>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Sample setChild={this.setEmpValue.bind(this)} isError={this.state.adminEmailErr} reqFlag={true} name={'eeail'} label={'Email'} value={this.state.adminEmail} style={customStyle.textFieldWrpAgent} length={50} fieldType={'email'} errMsg={'Enter valid email'} helperMsg={'Email required'} parentDetails={{ name: 'adminEmail' }}></Sample>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <div>
                                        <MuiPhoneNumber
                                            name={"phoneNumber"}
                                            required id="filled-required"
                                            label={"Mobile No."}
                                            data-cy="user-phone"
                                            variant="filled"
                                            defaultCountry={"us"}
                                            onlyCountries={["us"]}
                                            countryCodeEditable={false}
                                            autoComplete='off'
                                            value={this.state.adminPhone}
                                            length={17}
                                            onChange={(e) => this.setEmpValue(e, true, { name: 'adminPhone' })}
                                            disable={false} style={customStyle.textFieldWrpAgent} fieldType={'adminPhone'}
                                            errMsg={'Enter valid mobile no.'} helperMsg={'Mobile no. required'}
                                        />
                                        {this.state.phone.length == 17 ? null : <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-required mobileErr">Enter valid mobile no.</p>}
                                    </div>
                                </Grid> */}

                                {/* <Grid item xs={12} sm={10} style={{marginTop: 15, marginBottom: -15}}>
                                        <span style={customStyle.textBold}>Payroll Cycle</span>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <div>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    aria-label="days"
                                                    name="row-radio-buttons-group"
                                                    value={this.state.payrollCycle}
                                                    onChange={(event) => this.setState({ payrollCycle: event.target.value })}
                                                >
                                                    <FormControlLabel
                                                        value="Weekly"
                                                        control={<PurpleRadio />}
                                                        label="Weekly"
                                                    />
                                                    <FormControlLabel
                                                        value="BiWeekly"
                                                        control={<PurpleRadio />}
                                                        label="Every Two Weeks"
                                                    />
                                                    <FormControlLabel
                                                        value="Monthly"
                                                        control={<PurpleRadio />}
                                                        label="Monthly"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={10} style={customStyle.sectionPart}>
                                        <span style={customStyle.textBold}>Employer Contribution</span>
                                    </Grid>
                                    <Grid item xs={12} sm={10} style={{marginTop: -15, marginBottom: -15}}>
                                        <div>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    aria-label="days"
                                                    name="row-radio-buttons-group"
                                                    value={this.state.contributionType}
                                                    onChange={(event) => this.setState({ contributionType: event.target.value, employerContribution: '' })}
                                                >
                                                    <FormControlLabel
                                                        value="Doller"
                                                        control={<PurpleRadio />}
                                                        label="Fixed Amount"
                                                    />
                                                    <FormControlLabel
                                                        value="Percentage"
                                                        control={<PurpleRadio />}
                                                        label="Percentage of Monthly Contribution"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <Grid item xs={12} sm={6}>
                                            <Sample setChild={this.setEmpValue.bind(this)} name={'employerContribution'} label={`${!this.state.employerContribution ? this.state.contributionType == 'Doller' ? '$0' : '0%' : ''}`} value={this.state.employerContribution} disable={false} style={customStyle.textFieldWrpAgent} length={60} fieldType={'employerContribution'} errMsg={'Enter valid amount'} helperMsg={'Employer Contribution required'} parentDetails={{ name: 'employerContribution' }}></Sample>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={10} style={customStyle.sectionPart}>
                                        <span style={customStyle.textBold}>Setup ACH</span>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={false} name={'Bank_Name'} label={'Bank Name'} value={this.state.bankName} disable={false} style={customStyle.textFieldWrpAgent} length={60} fieldType={'bank_name'} errMsg={'Enter valid bank name'} parentDetails={{ name: 'bankName' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={false} name={'Account_Name'} label={'Name on Account'} value={this.state.accountName} disable={false} style={customStyle.textFieldWrpAgent} length={120} fieldType={'accountName'} errMsg={'Enter valid account name'} parentDetails={{ name: 'accountName' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={false} name={'Routing_Number'} label={'Routing Number'} value={this.state.routingNo} disable={false} style={customStyle.textFieldWrpAgent} length={9} fieldType={'routingNo'} errMsg={'Enter valid routing number'} parentDetails={{ name: 'routingNo' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <Sample setChild={this.setEmpValue.bind(this)} reqFlag={false} name={'Account_Number'} label={'Account Number'} value={this.state.accountNumber} disable={false} style={customStyle.textFieldWrpAgent} length={26} fieldType={'accountNumber'} errMsg={'ccount number must be 4 and up to 26 digits'} parentDetails={{ name: 'accountNumber' }}></Sample>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <Grid item xs={12} sm={6}>
                                            <CommonDropDwn setChild={this.setEmpValue.bind(this)} name={"Account_Type"} account={true} label={"Account Type"} value={this.state.accountType} disable={false} style={customStyle.dropDown} fieldType={"dropDwn"} List={this.state.accountTypes} parentDetails={{ name: "accountType" }}></CommonDropDwn>
                                        </Grid>
                                    </Grid> */}
                            </Grid>

                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        {/* {this.state.isRequired != '' &&
                        <text style={{color: 'red', marginRight: 20}}>{this.state.isRequired}</text>} */}
                        {/* {!this.state.acknowledged && this.state.employerEdit &&
                            <NextButton style={{ margin: '0', backgroundColor: '#4782c4', marginRight: '10px', width: '150px', height: '40px' }} onClick={() => this.sendAuth()}>Send Auth</NextButton>
                        } */}
                        <NextButton style={{ margin: '0', backgroundColor: !this.state.userValid ? '#4782c4' : '#999', marginRight: '10px', width: '95px', height: '40px' }} disabled={this.state.userValid} onClick={() => this.checkEnable(true)}>{this.state.employerEdit ? "UPDATE" : "ADD"}</NextButton>
                        <CustomButton style={{ height: '40px', backgroundColor: '#4782c4', color: 'ffffff' }} onClick={() => this.closeEmployerModal()}>Cancel</CustomButton>
                    </Modal.Footer>
                </Modal>

                <Modal size="md" show={this.state.msgModal} onHide={(event) => this.setState({ msgModal: false, loaderShow: false })} centered backdrop='static'>
                    <Modal.Header style={styles.modal_header} closeButton>
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '15px' }}>
                        {
                            this.state.loaderShow ? <Loader></Loader> : ''
                        }

                        <div style={customStyle.HomeContainer}>
                            <div style={customStyle.HomeMAinChild}>
                                <p dangerouslySetInnerHTML={{ __html: this.state.errMsg }}></p>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton style={{ height: '40px', backgroundColor: '#4782c4' }} onClick={() => this.setState({ msgModal: false, loaderShow: false })}>Ok</CustomButton>
                    </Modal.Footer>
                </Modal>

                <Modal size="lg" show={this.state.viewEmployerModal} onHide={(event) => this.setState({ viewEmployerModal: false, loaderShow: false, employerDetailsObj: [] })} centered backdrop='static'>
                    <Modal.Header style={styles.modal_header} closeButton>
                        <Modal.Title>View Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '15px', height: '330px', overflow: 'auto' }}>
                        {
                            this.state.loaderShow ? <Loader></Loader> : ''
                        }
                        <div style={{ flexGrow: 1 }}>
                            <Grid container spacing={2} justify="center">
                                <Grid item xs={12} sm={12}>
                                    {
                                        this.state.employerDetailsObj.map((row, index, key) => (

                                            <div key={index} style={{ display: 'flex', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', padding: '10px' }}>
                                                <Grid item xs={4} key={index} sm={4} md={4} lg={3}>
                                                    {row.key}
                                                </Grid>
                                                <Grid item xs={7} sm={7} md={7} lg={7} key={index + 'gd'} style={{ fontWeight: 'bold' }}>
                                                    {row.value}
                                                </Grid>
                                            </div>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton style={{ height: '40px', backgroundColor: '#4782c4' }} onClick={() => this.setState({ viewEmployerModal: false, loaderShow: false, employerDetailsObj: [] })}>Ok</CustomButton>
                    </Modal.Footer>
                </Modal>

                <Modal size="md" show={this.state.showAcknowledgementModal} onHide={(event) => this.setState({ showAcknowledgementModal: false, loaderShow: false })} backdrop='static' centered>
                    <Modal.Header style={customStyle.modal_header} closeButton>
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '15px' }}>
                        {
                            this.state.loaderShow ? <Loader></Loader> : ''
                        }

                        <div style={customStyle.HomeContainer}>
                            <div style={customStyle.HomeMAinChild}>
                                <text>The employer hasn't acknowledged the attestations and agreements with respect to Courtesy Payroll Deductions. This is a prerequisite for employees to be added for enrollment.</text>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton style={{ height: '40px' }} onClick={() => this.setState({ showAcknowledgementModal: false })}>Close</CustomButton>
                        <CustomButton style={{ height: '40px', marginLeft: '10px' }} onClick={() => this.sendAuth()}>Send Auth</CustomButton>
                    </Modal.Footer>
                </Modal>

                <Modal size={'sm'} show={this.state.authSent} onHide={() => this.setState({ authSent: false })} centered backdrop='static'>
                    <Modal.Header style={styles.modal_header} closeButton>
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={customStyle.tableSendquoteErrorList}>
                        <div style={{ textAlign: 'center' }}>
                            <text>Auth Email Sent!</text>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton style={{ height: '40px' }} onClick={() => this.setState({ authSent: false })}>Ok</CustomButton>
                    </Modal.Footer>
                </Modal>

                <Modal size={'md'} show={this.state.userModal} onHide={() => this.setState({ userModal: false })} centered backdrop='static'>
                    <Modal.Header style={styles.modal_header} closeButton>
                        <Modal.Title>Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={customStyle.tableSendquoteErrorList}>
                        <div style={{ textAlign: 'center' }}>
                            <p dangerouslySetInnerHTML={{ __html: this.state.userMsg }}></p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton style={{ height: '40px' }} onClick={() => this.setState({ userModal: false })}>Ok</CustomButton>
                    </Modal.Footer>
                </Modal>

                <Modal size={'md'} show={this.state.showTrialModal} onHide={() => this.setState({ showTrialModal: false, employerMode: 'trial' })} centered backdrop='static'>
                    <Modal.Header style={styles.modal_header} closeButton>
                        <Modal.Title>Select Option</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={customStyle.tableSendquoteErrorList}>
                        <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    row
                                    aria-label="mode"
                                    name="row-radio-buttons-group"
                                    value={this.state.employerMode}
                                    onChange={(event) => this.setState({ employerMode: event.target.value })}
                                >
                                    <FormControlLabel
                                        value="trial"
                                        control={<PurpleRadio />}
                                        label="With Trial"
                                    />
                                    <FormControlLabel
                                        value="non-trial"
                                        control={<PurpleRadio />}
                                        label="Without Trial"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton style={{ height: '40px' }} onClick={() => this.launchPortal()}>Continue</CustomButton>
                    </Modal.Footer>
                </Modal>

                <div style={{ fontSize: '11px', marginTop: '15px', fontFamily: "Roboto, Arial, Helvetica, sans-serif" }}>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default Employer;